import { combineReducers } from './combineReducers';
import userReducer from './user/user.reducer';

export const initialState: AppState = {
  user: {
    hasSeenTutorial: false,
    hasRestoredPurchases: false,
    darkMode: false,
    menuEnabled: true,
    isLoggedin: false,
    loading: false,
    listenMode: false,
  },
};

export const reducers = combineReducers({
  user: userReducer,
});

export type AppState = ReturnType<typeof reducers>;
