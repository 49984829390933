/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  useIonViewDidEnter,
  IonSkeletonText,
  IonAlert,
  IonCard,
  IonCardContent,
  IonTitle,
  IonListHeader,
} from '@ionic/react';

import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import SubPyramidItem from '../components/SubPyramidItem';

const GET_PYRAMID = gql`
  query pyramid($title: String!) {
    pyramid(where: { title: $title }) {
      description
      image
      subPyramids {
        title
      }
    }
  }
`;

const PyramidDetail: React.FC<RouteComponentProps> = ({ match, history }) => {
  const [getPyramid, { error, data: lazyData }] = useLazyQuery(GET_PYRAMID, { fetchPolicy: 'no-cache' });
  const [finalData, setFinalData] = useState<any>();
  const [title, setTitle] = useState('');

  const [alert, setAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const pageRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    if ('title' in match.params) {
      // eslint-disable-next-line
      let title = match.params['title']
      getPyramid({ variables: { title } });
      setTitle(title);
    }
  });

  if (error) {
    if (alert !== error.message) {
      setAlert(error.message.replace('GraphQL error: ', ''));
      if (!showAlert) {
        setShowAlert(true);
      }
    }
  }

  if (lazyData && finalData !== lazyData) {
    setFinalData(lazyData);
  }

  let pyramidDetailsHtml: any = (
    <div className="ion-padding">
      <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
      <span><IonSkeletonText animated style={{ width: '30%' }} /></span>
    </div>
  );
  if (finalData) {
    const { pyramid } = finalData;

    const subPyramidListItems = [];
    for (let i = 0; i < pyramid.subPyramids.length; i += 1) {
      const subPyramid = pyramid.subPyramids[i];
      subPyramidListItems.push(
        <SubPyramidItem
          key={subPyramid.title}
          subPyramid={subPyramid}
          history={history}
        />,
      );
    }

    pyramidDetailsHtml = (
      <IonCard>
        <img src={pyramid.image} alt={`${pyramid.title}logo`} />
        <IonCardContent>
          {pyramid.description}
          <br />
          <br />
          <IonListHeader className="ion-padding">Sub Pyramids</IonListHeader>
          {subPyramidListItems}
        </IonCardContent>
      </IonCard>
    );
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/explore" />
          </IonButtons>

          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {pyramidDetailsHtml}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default PyramidDetail;
