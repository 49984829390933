/* eslint-disable react/prop-types */
import React from 'react';

import {
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
} from '@ionic/react';

interface TechListItemProps {
  tech: any,
  history: any
}

const TechListItem: React.FC<TechListItemProps> = ({ tech, history }) => (
  <IonCard
    button
    onClick={() => { history.push(`/tabs/tech/${tech.title}`); }}
  >
    <img
      src={tech.image}
      alt={tech.title}
      style={{
        width: '100%', height: '200px', objectFit: 'contain', padding: '16px',
      }}
    />

    <IonCardHeader onClick={() => { history.push(`/tabs/tech/${tech.title}`); }}>
      <IonCardTitle>{tech.title}</IonCardTitle>
      <IonCardSubtitle mode="md" style={{ marginTop: '16px' }}>{`${tech.subPyramid.pyramid.title} / ${tech.subPyramid.title}`}</IonCardSubtitle>
    </IonCardHeader>
  </IonCard>
);

export default TechListItem;
