import { UserActions } from './user.actions';
import { UserState } from './user.state';

export default function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading };
    case 'set-user-data':
      return { ...state, ...action.data };
    case 'set-email':
      return { ...state, email: action.email };
    case 'set-has-seen-tutorial':
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case 'set-has-restored-purchases':
      return { ...state, hasRestoredPurchases: action.hasRestoredPurchases };
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode };
    case 'set-menu-enabled':
      return { ...state, menuEnabled: action.menuEnabled };
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.loggedIn };
    case 'set-firebase-token':
      return { ...state, firebaseToken: action.firebaseToken };
    case 'set-firebase-uid':
      return { ...state, firebaseUid: action.firebaseUid };
    case 'set-listen-mode':
      return { ...state, listenMode: action.listenMode };
    default:
      return state;
  }
}
