/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect } from 'react-router';

import { connect } from '../data/connect';

interface StateProps {
  hasSeenTutorial: boolean;
  firebaseToken?: string;
}


interface TutorialProps extends StateProps { }

const HomeOrTutorial: React.FC<TutorialProps> = ({
  hasSeenTutorial, firebaseToken,
}) => {
  if (hasSeenTutorial) {
    if (firebaseToken) {
      return (<Redirect to="/tabs/library" />);
    }
    return (<Redirect to="/login" />);
  }
  return (<Redirect to="/tutorial" />);
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasSeenTutorial: state.user.hasSeenTutorial,
    firebaseToken: state.user.firebaseToken,
  }),
  component: HomeOrTutorial,
});
