/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  useIonViewDidEnter,
  IonSkeletonText,
  IonAlert,
  IonTitle,
  IonCard,
  IonCardContent,
  IonListHeader,
} from '@ionic/react';

import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import TechList from '../components/TechList';

const GET_SUBPYRAMID = gql`
  query subPyramid($title: String!) {
    subPyramid(where: { title: $title }) {
      description
      image
      teches {
        title
        image
        subPyramid {
          title
          pyramid {
            title
          }
        }
      }
    }
  }
`;

const SubPyramidDetail: React.FC<RouteComponentProps> = ({ match, history }) => {
  const [getSubPyramid, { error, data: lazyData }] = useLazyQuery(GET_SUBPYRAMID, { fetchPolicy: 'no-cache' });
  const [finalData, setFinalData] = useState<any>();
  const [title, setTitle] = useState('');

  const [alert, setAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const pageRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    if ('title' in match.params) {
      // eslint-disable-next-line
      let title = match.params['title']
      getSubPyramid({ variables: { title } });
      setTitle(title);
    }
  });

  if (error) {
    if (alert !== error.message) {
      setAlert(error.message.replace('GraphQL error: ', ''));
      if (!showAlert) {
        setShowAlert(true);
      }
    }
  }

  if (lazyData && finalData !== lazyData) {
    setFinalData(lazyData);
  }

  let pyramidDetailsHtml: any = (
    <div className="ion-padding">
      <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
      <span><IonSkeletonText animated style={{ width: '30%' }} /></span>
    </div>
  );
  if (finalData) {
    const { subPyramid } = finalData;

    const techListHtml = (<TechList teches={subPyramid.teches} history={history} />);
    pyramidDetailsHtml = (
      <>
        <IonCard>
          <img src={subPyramid.image} alt={`${subPyramid.title}logo`} />
          <IonCardContent>
            {subPyramid.description}
          </IonCardContent>
        </IonCard>

        <IonListHeader className="ion-padding">Technologies</IonListHeader>
        {techListHtml}
      </>
    );
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/explore" />
          </IonButtons>

          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {pyramidDetailsHtml}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default SubPyramidDetail;
