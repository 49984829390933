import React from 'react'
import { IonCol, IonCard, IonItem, IonThumbnail, IonSkeletonText, IonLabel, IonChip } from '@ionic/react'

export function getRandomColor () {
  var colors = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger']
  var randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex]
}

const baseSkeleton = (
  <IonItem>
    <IonThumbnail slot='start'>
      <IonSkeletonText animated />
    </IonThumbnail>
    <IonLabel>
      <h3>
        <IonSkeletonText animated style={{ width: '80%' }} />
      </h3>
      <p>
        <IonSkeletonText animated style={{ width: '60%' }} />
      </p>
      <p>
        <IonSkeletonText animated style={{ width: '30%' }} />
      </p>
    </IonLabel>
  </IonItem>
)

export function getSkeletonColList (count: number) {
  const skeletonItemList = []
  for (let index = 0; index < count; index++) {
    skeletonItemList.push(
      <IonCol key={index} size='12' size-md='6' className='ion-align-self-center'>
        <IonCard button>
          {baseSkeleton}
        </IonCard>
      </IonCol>
    )
  }
  return skeletonItemList
}

export function getSkeletonItemList (count: number) {
  const skeletonItemList = []
  for (let index = 0; index < count; index++) {
    skeletonItemList.push(
      <IonCard key={index} button>
        {baseSkeleton}
      </IonCard>
    )
  }
  return skeletonItemList
}

export function getSkeletonChipList (count: number, isOutline?: boolean) {
  const skeletonItemList = []
  for (let index = 0; index < count; index++) {
    skeletonItemList.push(
      <IonChip key={index} color={getRandomColor()} outline={isOutline}>
        <IonSkeletonText animated />
      </IonChip>
    )
  }
  return skeletonItemList
}
