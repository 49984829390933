import { Plugins } from '@capacitor/core';

export const KEY_DARK_MODE = 'darkMode';

export const KEY_HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
export const KEY_HAS_RESTORED_PURCHASES = 'hasRestoredPurchases';

export const KEY_HAS_LOGGED_IN = 'hasLoggedIn';
export const KEY_EMAIL = 'email';

export const KEY_FIREBASE_USER_TOKEN = 'idToken';
export const KEY_FIREBASE_USER_ID = 'firebaseUid';

const { Storage } = Plugins;

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: KEY_HAS_LOGGED_IN }),
    Storage.get({ key: KEY_HAS_SEEN_TUTORIAL }),
    Storage.get({ key: KEY_EMAIL }),
    Storage.get({ key: KEY_FIREBASE_USER_TOKEN }),
    Storage.get({ key: KEY_DARK_MODE }),
    Storage.get({ key: KEY_HAS_RESTORED_PURCHASES }),
    Storage.get({ key: KEY_FIREBASE_USER_ID }),
  ]);
  const isLoggedin = await response[0].value === 'true';
  const hasSeenTutorial = await response[1].value === 'true';
  const email = await response[2].value || undefined;
  const firebaseToken = await response[3].value || undefined;
  const darkMode = await response[4].value === 'true';
  const hasRestoredPurchases = await response[5].value === 'true';
  const firebaseUid = await response[6].value || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    email,
    firebaseToken,
    darkMode,
    hasRestoredPurchases,
    firebaseUid,
  };
  return data;
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: KEY_HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({ key: KEY_HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) });
};

export const setHasRestoredPurchasesData = async (hasRestoredPurchases: boolean) => {
  await Storage.set({
    key: KEY_HAS_RESTORED_PURCHASES,
    value: JSON.stringify(hasRestoredPurchases),
  });
};

export const setDarkModeData = async (darkMode: boolean) => {
  await Storage.set({ key: KEY_DARK_MODE, value: JSON.stringify(darkMode) });
};

export const setEmailData = async (email?: string) => {
  if (!email) {
    await Storage.remove({ key: KEY_EMAIL });
  } else {
    await Storage.set({ key: KEY_EMAIL, value: email });
  }
};

export const setFirebaseTokenData = async (firebaseToken?: string) => {
  if (!firebaseToken) {
    await Storage.remove({ key: KEY_FIREBASE_USER_TOKEN });
  } else {
    await Storage.set({ key: KEY_FIREBASE_USER_TOKEN, value: firebaseToken });
  }
};

export const setFirebaseUidData = async (firebaseUid?: string) => {
  if (!firebaseUid) {
    await Storage.remove({ key: KEY_FIREBASE_USER_ID });
  } else {
    await Storage.set({ key: KEY_FIREBASE_USER_ID, value: firebaseUid });
  }
};
