/* eslint-disable react/prop-types */
import React from 'react';

import {
  IonList, IonGrid, IonRow, IonCol,
} from '@ionic/react';
import TechListItem from './TechListItem';

interface TechListProps {
  teches: any[],
  history: any
}

const TechList: React.FC<TechListProps> = ({ teches, history }) => {
  const techColumns: any = [];
  teches.forEach((tech: any) => {
    techColumns.push(
      <IonCol key={tech.title} size="12" size-md="6" className="ion-align-self-center">
        <TechListItem tech={tech} history={history} />
      </IonCol>,
    );
  });
  return (
    <IonList lines="none">
      <IonGrid fixed>
        <IonRow align-items-stretch>
          {techColumns}
        </IonRow>
      </IonGrid>
    </IonList>
  );
};

export default TechList;
