/* eslint-disable react/prop-types */
import React from 'react';

import { IonLabel, IonChip } from '@ionic/react';
import { getRandomColor } from '../util/uiUtils';

interface SubPyramidItemProps {
  subPyramid: any,
  history: any
}

const SubPyramidItem: React.FC<SubPyramidItemProps> = ({ subPyramid, history }) => (
  <IonChip outline color={getRandomColor()} onClick={() => { history.push(`/tabs/subpyramid/${subPyramid.title}`); }}>
    <IonLabel>{subPyramid.title}</IonLabel>
  </IonChip>
);

export default SubPyramidItem;
