/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { loadingController, alertController } from '@ionic/core';
import {
  IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle,
} from '@ionic/react';
import {
  moon, logIn, person, personAdd, search, library, albums, informationCircle, refreshCircle,
} from 'ionicons/icons';

import { Deploy } from 'cordova-plugin-ionic';
import { Plugins } from '@capacitor/core';

import { connect } from '../data/connect';
import { setDarkMode } from '../data/user/user.actions';

import './Menu.css';

const routes = {
  appPages: [
    // { title: 'Home', path: '/tabs/home', icon: homeOutline },
    { title: 'Explore', path: '/tabs/explore', icon: search },
    { title: 'Library', path: '/tabs/library', icon: library },
  ],
  loggedInPages: [
    { title: 'Account', path: '/account', icon: person },
    // { title: 'Support', path: '/support', icon: help },
    // { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    // { title: 'Support', path: '/support', icon: help },
    { title: 'Signup', path: '/signup', icon: personAdd },
  ],
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const { Device } = Plugins;
let isWeb = false;

const Menu: React.FC<MenuProps> = ({
  // eslint-disable-next-line no-shadow
  darkMode, history, isAuthenticated, setDarkMode, menuEnabled,
}) => {
  const location = useLocation();

  useEffect(() => {
    async function checkIsWeb() {
      const info = await Device.getInfo();
      isWeb = (info.platform === 'web');
    }
    checkIsWeb();
  }, []);

  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>SkillsASAP</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>

        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {isAuthenticated
            ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
          <IonItem>
            <IonIcon slot="start" icon={moon} />
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
        </IonList>

        <IonList lines="none">
          <IonListHeader>About</IonListHeader>
          <IonMenuToggle auto-hide="false">
            <IonItem button onClick={() => { history.push('/tutorial'); }}>
              <IonIcon slot="start" icon={albums} />
              Show Intro
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide="false">
            <IonItem
              button
              onClick={() => {
                history.push('/about');
              }}
            >
              <IonIcon slot="start" icon={informationCircle} />
              About SkillsASAP
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <IonList lines="none">
          {!isWeb
            && (
            <IonMenuToggle auto-hide="false">
              <IonItem
                button
                onClick={async () => {
                  const loading = await loadingController.create({ message: 'Checking for Updates' });
                  loading.present();
                  const update = await Deploy.checkForUpdate();
                  if (update.available) {
                    await Deploy.downloadUpdate((progress) => {
                      loading.message = `Downloading Update: ${progress}%`;
                    });
                    await Deploy.extractUpdate((progress) => {
                      loading.message = `Extracting Update: ${progress}%`;
                    });
                    loading.message = 'Reloading App';
                    await Deploy.reloadApp();
                  } else {
                    loading.dismiss();
                    const alert = await alertController.create({
                      message: 'SkillsASAP is up to date.',
                      buttons: [{ text: 'Ok', handler: () => { } }],
                    });
                    await alert.present();
                  }
                }}
              >
                <IonIcon slot="start" icon={refreshCircle} />
                <IonLabel>Check for Updates</IonLabel>
              </IonItem>
            </IonMenuToggle>
            )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.user.menuEnabled,
  }),
  mapDispatchToProps: ({
    setDarkMode,
  }),
  component: withRouter(Menu),
});
