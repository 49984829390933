/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RefresherEventDetail } from '@ionic/core';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  useIonViewDidEnter,
  IonAlert,
  IonListHeader,
} from '@ionic/react';

import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { getSkeletonChipList } from '../util/uiUtils';
import SubPyramidItem from '../components/SubPyramidItem';
import PyramidItem from '../components/PyramidItem';

const GET_PYRAMIDS = gql`
  query pyramids {
    pyramids(orderBy: createdAt_DESC) {
      title
      subPyramids {
        title
      }
    }
  }
`;

const ExplorePage: React.FC<RouteComponentProps> = ({ history }) => {
  const [getPyramids, { loading, error, data: lazyData }] = useLazyQuery(GET_PYRAMIDS, { fetchPolicy: 'no-cache' });
  const [finalData, setFinalData] = useState<any>();

  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

  const [alert, setAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const pageRef = useRef<HTMLElement>(null);

  useIonViewDidEnter(() => { getPyramids(); });

  if (error) {
    if (alert !== error.message) {
      setAlert(error.message.replace('GraphQL error: ', ''));
      if (!showAlert) {
        setShowAlert(true);
      }
    }
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getPyramids();

    const interval = setInterval(() => {
      if (!loading) {
        event.detail.complete();
        clearInterval(interval);
      }
    }, 1000);
  }

  if (lazyData && finalData !== lazyData) {
    setFinalData(lazyData);
  }

  let pyramidsHtml: any[] = getSkeletonChipList(20);
  let subPyramidsHtml: any[] = getSkeletonChipList(20, true);
  if (finalData) {
    pyramidsHtml = [];
    subPyramidsHtml = [];
    finalData.pyramids.forEach((pyramid: any) => {
      pyramidsHtml.push(
        <PyramidItem key={pyramid.title} pyramid={pyramid} history={history} />,
      );
      pyramid.subPyramids.forEach((subPyramid: any) => {
        subPyramidsHtml.push(
          <SubPyramidItem
            key={subPyramid.title}
            subPyramid={subPyramid}
            history={history}
          />,
        );
      });
    });
  }

  return (
    <IonPage ref={pageRef} id="schedule-page">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>Explore</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <IonListHeader className="ion-padding">Pyramids</IonListHeader>
        {pyramidsHtml}

        <IonListHeader className="ion-padding">Sub Pyramids</IonListHeader>
        {subPyramidsHtml}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default ExplorePage;
