/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RefresherEventDetail } from '@ionic/core';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonAlert,
  useIonViewDidEnter,
} from '@ionic/react';
import { search } from 'ionicons/icons';

import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { getSkeletonColList } from '../util/uiUtils';

import TechList from '../components/TechList';

import { connect } from '../data/connect';
import { setMenuEnabled } from '../data/user/user.actions';

const GET_TECHS = gql`
  query teches {
    teches(where: { releaseTrack: OPEN_BETA }, orderBy: createdAt_DESC) {
      title
      image
      subPyramid {
        title
        pyramid {
          title
        }
      }
    }
  }
`;

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled;
}

interface OwnProps extends RouteComponentProps {}

interface TechListPageProps extends OwnProps, DispatchProps { }

// eslint-disable-next-line no-shadow
const TechListPage: React.FC<TechListPageProps> = ({ history, setMenuEnabled }) => {
  const [getTechs, { loading, error, data: lazyData }] = useLazyQuery(GET_TECHS, { fetchPolicy: 'no-cache' });
  const [finalData, setFinalData] = useState<any>();

  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

  const [searchText, setSearchText] = useState('');

  const [alert, setAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const pageRef = useRef<HTMLElement>(null);

  useIonViewDidEnter(() => {
    setMenuEnabled(true);
    getTechs();
  });

  if (error) {
    if (alert !== error.message) {
      setAlert(error.message.replace('GraphQL error: ', ''));
      if (!showAlert) {
        setShowAlert(true);
      }
    }
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getTechs();

    const interval = setInterval(() => {
      if (!loading) {
        event.detail.complete();
        clearInterval(interval);
      }
    }, 1000);
  }

  if (lazyData && finalData !== lazyData) {
    setFinalData(lazyData);
  }

  let techesHtml: any = getSkeletonColList(20);
  if (finalData) {
    const teches = [];
    for (let i = 0; i < finalData.teches.length; i += 1) {
      const tech = finalData.teches[i];
      if (tech.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        teches.push(tech);
      }
    }
    techesHtml = (<TechList teches={teches} history={history} />);
  }

  return (
    <IonPage ref={pageRef} id="schedule-page">
      <IonHeader translucent>
        <IonToolbar>
          {!showSearchbar
            && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            )}
          {!showSearchbar
            && <IonTitle>Library</IonTitle>}
          {showSearchbar
            && <IonSearchbar showCancelButton="always" placeholder="Search" onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)} onIonCancel={() => setShowSearchbar(false)} />}

          <IonButtons slot="end">
            {!showSearchbar
              && (
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search} />
              </IonButton>
              )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        {techesHtml}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: ({
    setMenuEnabled,
  }),
  component: TechListPage,
});
