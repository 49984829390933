import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

var firebaseConfig = {
  apiKey: 'AIzaSyALcHnG0IpJ8wj7ajdAfEeP1hETdEuWW5w',
  authDomain: 'skillsasap.firebaseapp.com',
  databaseURL: 'https://skillsasap.firebaseio.com',
  projectId: 'skillsasap',
  storageBucket: 'skillsasap.appspot.com',
  messagingSenderId: '28845882011',
  appId: '1:28845882011:web:1b772c528d3e4d1afba43e',
  measurementId: 'G-G6770KW77N'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

ReactDOM.render(<App />, document.getElementById('root'))
