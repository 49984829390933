/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonSkeletonText,
  IonAlert,
  IonRow,
  IonCol,
  IonGrid,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonNote,
  IonCard,
  IonCardContent,
  IonLoading,
} from '@ionic/react';
import {
  bookOutline, desktopOutline, wallet, arrowUndo,
} from 'ionicons/icons';

import { Plugins } from '@capacitor/core';

import { gql } from 'apollo-boost';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2';
import PyramidItem from '../components/PyramidItem';
import SubPyramidItem from '../components/SubPyramidItem';

import { connect } from '../data/connect';
import { setListenMode, setHasRestoredPurchases } from '../data/user/user.actions';

const GET_TECHS = gql`
  query tech($title: String!, $email: String!) {
    tech(where: { title: $title }) {
      id
      title
      description
      image
      subPyramid {
        title
        pyramid {
          title
        }
      }
      blinks(orderBy: index_ASC) {
        title
      }
      handsOnTopics(orderBy: index_ASC) {
        index
        title
        dockerImage
      }
      blinksIapId
      purchases(
        where: {
          user: { email: $email }
          type: BLINKS
        }
      ) {
        source
      }
    }
  }
`;

const CREATE_PURCHASE = gql`
  mutation createPurchase($type: PurchaseType!, $source: PurchaseSource!, $techTitle: String!) {
    createPurchase(type: $type, source: $source, techTitle: $techTitle) {
      id
    }
  }
`;

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  email?: string;
  hasRestoredPurchases: boolean;
}

interface DispatchProps {
  setListenMode: typeof setListenMode;
  setHasRestoredPurchases: typeof setHasRestoredPurchases;
}

interface TechDetailProps extends OwnProps, StateProps, DispatchProps { }

const { Device } = Plugins;

const TechDetail: React.FC<TechDetailProps> = ({
  match, history, email, setListenMode: setListenModeProp,
  setHasRestoredPurchases: setHasRestoredPurchasesProp, hasRestoredPurchases,
}) => {
  const [getTechs, { error, data: lazyData }] = useLazyQuery(GET_TECHS, { fetchPolicy: 'no-cache' });
  const [createPurchase] = useMutation(CREATE_PURCHASE);

  const [techDetails, setTechDetails] = useState<any>();
  const [title, setTitle] = useState('');

  const [alert, setAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [showBlinkListModal, setShowBlinkListModel] = useState(false);
  const [showHandsOnTopicListModal, setShowHandsOnTopicListModal] = useState(false);

  const [purchased, setPurchased] = useState(false);

  const [showLoading, setShowLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  const pageRef = useRef<any>(null);

  useEffect(() => {
    if ('techTitle' in match.params && email) {
      // eslint-disable-next-line
      let title = match.params['techTitle']
      getTechs({ variables: { title, email } });
      setTitle(title);
    }
  }, [email, getTechs, match.params]);

  useEffect(() => {
    if (techDetails) {
      if (!techDetails.blinksIapId) {
        setPurchased(true);
      } else if (techDetails.purchases.length > 0 && hasRestoredPurchases) {
        setPurchased(true);
      }
    }
  }, [techDetails, hasRestoredPurchases]);

  useEffect(() => {
    setShowLoading(false);
  }, [purchased, hasRestoredPurchases]);

  if (error) {
    if (alert !== error.message) {
      setAlert(error.message.replace('GraphQL error: ', ''));
      setShowAlert(true);
    }
  }

  if (lazyData && techDetails !== lazyData.tech) {
    setTechDetails(lazyData.tech);
  }

  async function onPurchaseBlinksClick(shouldRestore: boolean) {
    const info = await Device.getInfo();
    if (info.platform === 'web') {
      setAlert('Purchase Blinks using iOS or Android App');
      setShowAlert(true);
      return;
    }

    if (shouldRestore) {
      setLoadingMessage('Restoring your Purchases...');
    } else {
      setLoadingMessage('Purchasing Blinks...');
    }
    setShowLoading(true);

    const iapId = techDetails.blinksIapId;
    // InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
    if (email) {
      InAppPurchase2.applicationUsername = email;
    }
    InAppPurchase2.validator = 'https://validator.fovea.cc/v1/validate?appName=com.skillsasap.techblinks&apiKey=6f17bbf0-7bc6-4eed-84f5-90f764072334';

    InAppPurchase2.error((iapError: any) => {
      setAlert(iapError.message);
      setShowAlert(true);
      setShowLoading(false);
    });

    if (shouldRestore) {
      InAppPurchase2.when('product')
        .verified(() => {
          if (!hasRestoredPurchases) {
            setAlert('Successfully Restored your Purchases');
            setShowAlert(true);
            setHasRestoredPurchasesProp(true);
          }
        });
    } else {
      let orderStarted = false;
      let createPurchaseStarted = false;
      InAppPurchase2.when(iapId)
        .updated(async (product: IAPProduct) => {
          if (product.state === 'valid' && !orderStarted) {
            orderStarted = true;
            try {
              setLoadingMessage('Purchasing...');
              InAppPurchase2.order(iapId);
            } catch (orderError) {
              setAlert(orderError.message);
              setShowAlert(true);
              setShowLoading(false);
            }
          }
        })
        .approved((product: IAPProduct) => {
          setLoadingMessage('Verifing purchase...');
          product.verify();
        })
        .verified((product: IAPProduct) => {
          product.finish();
        })
        .owned(async () => {
          if (!createPurchaseStarted) {
            createPurchaseStarted = true;
            try {
              await createPurchase({
                variables: {
                  type: 'BLINKS',
                  source: info.platform === 'ios' ? 'APP_STORE' : 'PLAY_STORE',
                  techTitle: techDetails.title,
                },
              });
            } catch (createPurchaseError) {
              setAlert(createPurchaseError.message.replace('GraphQL error: ', ''));
              setShowAlert(true);
            }
          }

          setPurchased(true);
          setHasRestoredPurchasesProp(true);
        });
    }

    InAppPurchase2.register({ type: InAppPurchase2.NON_CONSUMABLE, id: 'com.skillsasap.app.docker_blinks' });
    InAppPurchase2.register({ type: InAppPurchase2.NON_CONSUMABLE, id: 'com.skillsasap.app.cassandra_blinks' });
    InAppPurchase2.register({ type: InAppPurchase2.NON_CONSUMABLE, id: 'com.skillsasap.app.kafka_blinks' });
    InAppPurchase2.register({ type: InAppPurchase2.NON_CONSUMABLE, id: 'com.skillsasap.app.kubernetes_blinks' });
    InAppPurchase2.register({ type: InAppPurchase2.NON_CONSUMABLE, id: 'com.skillsasap.app.chef_blinks' });

    InAppPurchase2.refresh();
  }

  let techDetailsHtml: any = (
    <div className="ion-padding">
      <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
      <span><IonSkeletonText animated style={{ width: '30%' }} /></span>
    </div>
  );
  if (techDetails) {
    const blinkListItems = [];
    for (let i = 0; i < techDetails.blinks.length; i += 1) {
      const blink = techDetails.blinks[i];
      blinkListItems.push(
        <IonItem key={i}>
          <IonNote slot="start">{i + 1}</IonNote>
          <IonLabel>{blink.title}</IonLabel>
        </IonItem>,
      );
    }

    const handsOnTopicListItems = [];
    for (let i = 0; i < techDetails.handsOnTopics.length; i += 1) {
      const handsOnTopic = techDetails.handsOnTopics[i];
      handsOnTopicListItems.push(
        <IonItem key={i}>
          <IonNote slot="start">{i + 1}</IonNote>
          <IonLabel>{handsOnTopic.title}</IonLabel>
          {handsOnTopic.dockerImage === 'SUPER' && (
            <IonButton
              routerLink={`/tabs/tech/${title}/hands-on/${handsOnTopic.index}`}
              onClick={() => setShowHandsOnTopicListModal(false)}
              expand="block"
            >
              Start
            </IonButton>
          )}
        </IonItem>,
      );
    }

    techDetailsHtml = (
      <div className="ion-padding">
        <img src={techDetails.image} alt={`${techDetails.title}logo`} style={{ width: '100%', height: '200px', objectFit: 'contain' }} />

        {techDetails.blinks.length > 0 && !purchased && (
          <IonButton
            expand="block"
            onClick={async () => onPurchaseBlinksClick(false)}
          >
            <IonIcon slot="start" icon={wallet} />
            Purchase Blinks
          </IonButton>
        )}
        {techDetails.blinks.length > 0 && !hasRestoredPurchases && (
          <IonButton
            style={{ marginTop: '16px' }}
            expand="block"
            color="success"
            onClick={async () => onPurchaseBlinksClick(true)}
          >
            <IonIcon slot="start" icon={arrowUndo} />
            Restore Purchases
          </IonButton>
        )}

        {techDetails.blinks.length > 0 && purchased && (
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol size="6">
                <IonButton routerLink={`/tabs/tech/${title}/blinks`} onClick={() => setListenModeProp(false)} expand="block">Read</IonButton>
              </IonCol>

              <IonCol size="6">
                <IonButton routerLink={`/tabs/tech/${title}/blinks`} onClick={() => setListenModeProp(true)} expand="block">Listen</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        <IonCard>
          <IonCardContent>
            {techDetails.description}
            <hr />
            <PyramidItem pyramid={techDetails.subPyramid.pyramid} history={history} />
            <SubPyramidItem subPyramid={techDetails.subPyramid} history={history} />
          </IonCardContent>
        </IonCard>

        {techDetails.blinks.length > 0 && (
          <IonItem lines="none" button onClick={() => setShowBlinkListModel(true)}>
            <IonIcon icon={bookOutline} slot="start" />
            <IonLabel>{`${techDetails.blinks.length} Blinks`}</IonLabel>
          </IonItem>
        )}

        {techDetails.handsOnTopics.length > 0 && (
          <>
            <IonItem lines="none" button onClick={() => setShowHandsOnTopicListModal(true)}>
              <IonIcon icon={desktopOutline} slot="start" />
              <IonLabel>{`${techDetails.handsOnTopics.length} Hands-On Topics`}</IonLabel>
            </IonItem>
          </>
        )}

        <IonModal
          isOpen={showBlinkListModal}
          swipeToClose
          presentingElement={pageRef.current}
          onDidDismiss={() => setShowBlinkListModel(false)}
        >
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>{`${title} Blinks`}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowBlinkListModel(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonList>{blinkListItems}</IonList>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showHandsOnTopicListModal}
          swipeToClose
          presentingElement={pageRef.current}
          onDidDismiss={() => setShowHandsOnTopicListModal(false)}
        >
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>{`${title} Hands-On Topics`}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowHandsOnTopicListModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen className="ion-padding">
            Download the Desktop App at
            {' '}
            <a href="https://skillsasap.com">https://skillsasap.com</a>
            {' '}
            to view the Hands-On
            <IonList lines="none">{handsOnTopicListItems}</IonList>
          </IonContent>
        </IonModal>
      </div>
    );
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/library" />
          </IonButtons>

          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {techDetailsHtml}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={loadingMessage}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    email: state.user.email,
    hasRestoredPurchases: state.user.hasRestoredPurchases,
  }),
  mapDispatchToProps: ({
    setListenMode, setHasRestoredPurchases,
  }),
  component: TechDetail,
});
