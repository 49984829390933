import {
  getUserData,
  setIsLoggedInData,
  setEmailData,
  setHasSeenTutorialData,
  setFirebaseTokenData,
  setDarkModeData,
  setHasRestoredPurchasesData,
  setFirebaseUidData,
} from '../dataApi';
import { ActionType } from '../../util/types';
import { UserState } from './user.state';

export const setLoading = (isLoading: boolean) => ({
  type: 'set-user-loading',
  isLoading,
} as const);

export const setData = (data: Partial<UserState>) => ({
  type: 'set-user-data',
  data,
} as const);

export const setEmail = (email?: string) => async () => {
  await setEmailData(email);
  return ({
    type: 'set-email',
    email,
  } as const);
};

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
  dispatch(setEmail());
};

export const setIsLoggedIn = (loggedIn: boolean) => async () => {
  await setIsLoggedInData(loggedIn);
  return ({
    type: 'set-is-loggedin',
    loggedIn,
  } as const);
};


export const setHasSeenTutorial = (hasSeenTutorial: boolean) => async () => {
  await setHasSeenTutorialData(hasSeenTutorial);
  return ({
    type: 'set-has-seen-tutorial',
    hasSeenTutorial,
  } as const);
};

export const setHasRestoredPurchases = (hasRestoredPurchases: boolean) => async () => {
  await setHasRestoredPurchasesData(hasRestoredPurchases);
  return ({
    type: 'set-has-restored-purchases',
    hasRestoredPurchases,
  } as const);
};

export const setDarkMode = (darkMode: boolean) => async () => {
  await setDarkModeData(darkMode);
  return ({
    type: 'set-dark-mode',
    darkMode,
  } as const);
};

export const setMenuEnabled = (menuEnabled: boolean) => ({
  type: 'set-menu-enabled',
  menuEnabled,
} as const);

export const setFirebaseToken = (firebaseToken?: string) => async () => {
  await setFirebaseTokenData(firebaseToken);
  return ({
    type: 'set-firebase-token',
    firebaseToken,
  } as const);
};

export const setFirebaseUid = (firebaseUid?: string) => async () => {
  await setFirebaseUidData(firebaseUid);
  return ({
    type: 'set-firebase-uid',
    firebaseUid,
  } as const);
};

export const setListenMode = (listenMode: boolean) => async () => ({
  type: 'set-listen-mode',
  listenMode,
} as const);

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setEmail>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setHasRestoredPurchases>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setMenuEnabled>
  | ActionType<typeof setFirebaseToken>
  | ActionType<typeof setFirebaseUid>
  | ActionType<typeof setListenMode>;
