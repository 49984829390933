import React from 'react';

import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonList, IonItem,
} from '@ionic/react';

import { connect } from '../data/connect';

import './Account.scss';

interface StateProps {
  email?: string;
}

// eslint-disable-next-line react/prop-types
const Account: React.FC<StateProps> = ({ email }) => (
  <IonPage id="account-page">
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Account</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      {email && (
      <div className="ion-padding-top ion-text-center">
        <img src="https://www.gravatar.com/avatar?d=mm&s=140" alt="avatar" />
        <h2>{email}</h2>
        <IonList inset>
          <IonItem routerLink="/logout" routerDirection="none">Logout</IonItem>
        </IonList>
      </div>
      )}
    </IonContent>
  </IonPage>
);

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    email: state.user.email,
  }),
  component: Account,
});
