/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonAlert,
  IonLoading,
  useIonViewDidEnter,
} from '@ionic/react';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

import { connect } from '../data/connect';
import {
  setIsLoggedIn, setEmail, setMenuEnabled, setFirebaseToken,
} from '../data/user/user.actions';

import 'firebase/auth';

import './Login.scss';

const SIGN_UP_MUTATION = gql`
  mutation signup(
    $name: String!
    $phone: String!
    $email: String!
    $password: String!
  ) {
    signup(name: $name, phone: $phone, email: $email, password: $password) {
      firebaseToken
    }
  }
`;

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setEmail: typeof setEmail;
  setMenuEnabled: typeof setMenuEnabled;
  setFirebaseToken: typeof setFirebaseToken;
}

interface LoginProps extends OwnProps, DispatchProps { }

const Signup: React.FC<LoginProps> = ({
  // eslint-disable-next-line no-shadow
  setIsLoggedIn, history, setEmail: setEmailAction, setMenuEnabled, setFirebaseToken,
}) => {
  const [signUp] = useMutation(SIGN_UP_MUTATION);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmailInput] = useState('');
  const [password, setPassword] = useState('');

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    setMenuEnabled(false);
  });

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!name) {
      setNameError(true);
    }
    if (!phone) {
      setPhoneError(true);
    }
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }

    if (name && phone && email && password) {
      setShowLoading(true);
      try {
        const token = (
          await signUp({
            variables: {
              name, phone, email, password,
            },
          })
        ).data.signup.firebaseToken;
        setFirebaseToken(token);
        setEmailAction(email);
        setIsLoggedIn(true);

        setShowLoading(false);
        setMenuEnabled(true);
        history.push('/tabs/library', { direction: 'none' });
      } catch (error) {
        setShowLoading(false);
        setAlertMessage(error.message.replace('GraphQL error: ', ''));
        setShowAlert(true);
      }
    }
  };

  return (
    <IonPage id="signup-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Sign Up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="login-logo">
          <img src="assets/img/appicon.png" alt="SkillsASAP logo" />
        </div>

        <form noValidate onSubmit={login}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Name</IonLabel>
              <IonInput
                name="name"
                type="text"
                value={name}
                spellCheck={false}
                onIonChange={(e) => {
                  setName(e.detail.value!);
                  setNameError(false);
                }}
                required
              />
            </IonItem>

            {formSubmitted && nameError
              && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Name is required
                </p>
              </IonText>
              )}

            <IonItem>
              <IonLabel position="stacked" color="primary">Phone</IonLabel>
              <IonInput
                name="phone"
                type="tel"
                value={phone}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  setPhone(e.detail.value!);
                  setPhoneError(false);
                }}
                required
              />
            </IonItem>

            {formSubmitted && phoneError
              && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Phone is required
                </p>
              </IonText>
              )}

            <IonItem>
              <IonLabel position="stacked" color="primary">Email</IonLabel>
              <IonInput
                name="email"
                type="text"
                value={email}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  setEmailInput(e.detail.value!);
                  setEmailError(false);
                }}
                required
              />
            </IonItem>

            {formSubmitted && emailError
              && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Email is required
                </p>
              </IonText>
              )}

            <IonItem>
              <IonLabel position="stacked" color="primary">Password</IonLabel>
              <IonInput
                name="password"
                type="password"
                value={password}
                onIonChange={(e) => {
                  setPassword(e.detail.value!);
                  setPasswordError(false);
                }}
              />
            </IonItem>

            {formSubmitted && passwordError
              && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Password is required
                </p>
              </IonText>
              )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton disabled={showLoading} type="submit" expand="block">Sign Up</IonButton>
            </IonCol>
            <IonCol>
              <IonButton disabled={showLoading} routerLink="/login" color="light" expand="block">Login</IonButton>
            </IonCol>
          </IonRow>
        </form>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alertMessage}
          buttons={['OK']}
        />

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message="Please wait..."
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn, setEmail, setMenuEnabled, setFirebaseToken,
  },
  component: Signup,
});
