import React from 'react'

import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonBackButton, IonTitle } from '@ionic/react'

import Unity, { UnityContent } from 'react-unity-webgl'

const unityContent = new UnityContent(
  'assets/Cassandra/WebGL.json',
  'assets/Cassandra/UnityLoader.js'
)

const UnityPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle />
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/tabs/library' />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='ion-padding'>
        <Unity unityContent={unityContent} />
      </IonContent>
    </IonPage>
  )
}

export default UnityPage
