import React from 'react';
import {
  IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel,
} from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { search, library } from 'ionicons/icons';

import TechListPage from './TechListPage';
import TechDetail from './TechDetail';

import ExplorePage from './ExplorePage';
import PyramidDetail from './PyramidDetail';
import SubPyramidDetail from './SubPyramidDetail';

import BlinkPage from './BlinkPage';
import HandsOnPage from './HandsOnPage';

interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => (
  <IonTabs>
    <IonRouterOutlet>
      <Redirect path="/tabs" to="/tabs/library" exact />
      {/*
          Using the render method prop cuts down the number of renders
           your components will have due to route changes.
          Use the component prop when your component depends
          on the RouterComponentProps passed in automatically.
        */}
      <Route path="/tabs/home" component={TechListPage} exact />

      <Route path="/tabs/explore" component={ExplorePage} exact />

      <Route path="/tabs/pyramid/:title" component={PyramidDetail} exact />
      <Route path="/tabs/subpyramid/:title" component={SubPyramidDetail} exact />

      <Route path="/tabs/tech/:techTitle" component={TechDetail} exact />
      <Route path="/tabs/tech/:techTitle/blinks" component={BlinkPage} exact />
      <Route path="/tabs/tech/:techTitle/hands-on/:index" component={HandsOnPage} exact />

      <Route path="/tabs/library" component={TechListPage} exact />
    </IonRouterOutlet>
    <IonTabBar slot="bottom">
      {/* <IonTabButton tab='home' href='/tabs/home'>
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton> */}
      <IonTabButton tab="explore" href="/tabs/explore">
        <IonIcon icon={search} />
        <IonLabel>Explore</IonLabel>
      </IonTabButton>
      <IonTabButton tab="library" href="/tabs/library">
        <IonIcon icon={library} />
        <IonLabel>Library</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </IonTabs>
);

export default MainTabs;
