import React from 'react'
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonTitle, IonCard, IonCardContent } from '@ionic/react'

interface AboutProps { }

const About: React.FC<AboutProps> = () => {
  return (
    <IonPage id='about-page'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuButton />
          </IonButtons>
          <IonTitle>About SkillsASAP</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='login-logo'>
          <img src='assets/img/appicon.png' alt='SkillsASAP logo' />
        </div>

        <IonCard>
          <IonCardContent>
            Writing these TechBlinks has been a labour of love for the SkillsASAP team and we hope you have enjoyed reading it as much as we have enjoyed writing it.
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default React.memo(About)
