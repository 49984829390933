/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonAlert,
  useIonViewDidEnter,
  IonLoading,
} from '@ionic/react';

import * as firebase from 'firebase/app';
import { connect } from '../data/connect';
import { setIsLoggedIn, setEmail, setMenuEnabled } from '../data/user/user.actions';

import 'firebase/auth';

import './Login.scss';

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setEmail: typeof setEmail;
  setMenuEnabled: typeof setMenuEnabled;
}

interface LoginProps extends OwnProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({
  // eslint-disable-next-line no-shadow
  setIsLoggedIn, history, setEmail: setEmailAction, setMenuEnabled,
}) => {
  const [email, setEmailInput] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    setMenuEnabled(false);
  });

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }

    if (email && password) {
      setShowLoading(true);
      firebase.auth().signInWithEmailAndPassword(email, password)
        .catch((error) => {
          if (error) {
            setShowLoading(false);
            setAlertMessage(error.message.replace('GraphQL error: ', ''));
            setShowAlert(true);
          }
        })
        .then(async (userCredential) => {
          if (userCredential && userCredential.user) {
            setIsLoggedIn(true);
            setEmailAction(email);

            setShowLoading(false);
            setMenuEnabled(true);
            history.push('/tabs/library', { direction: 'none' });

            setEmailInput('');
            setPassword('');
          }
        });
    }
  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="login-logo">
          <img src="assets/img/appicon.png" alt="SkillsASAP logo" />
        </div>

        <form noValidate onSubmit={login}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Email</IonLabel>
              <IonInput
                name="email"
                type="email"
                value={email}
                onIonChange={(e) => setEmailInput(e.detail.value!)}
                required
              />
            </IonItem>

            {formSubmitted && emailError
              && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Email is required
                </p>
              </IonText>
              )}

            <IonItem>
              <IonLabel position="stacked" color="primary">Password</IonLabel>
              <IonInput name="password" type="password" value={password} onIonChange={(e) => setPassword(e.detail.value!)} required />
            </IonItem>

            {formSubmitted && passwordError
              && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Password is required
                </p>
              </IonText>
              )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton disabled={showLoading} type="submit" expand="block">Login</IonButton>
            </IonCol>
            <IonCol>
              <IonButton disabled={showLoading} routerLink="/signup" color="light" expand="block">Signup</IonButton>
            </IonCol>
          </IonRow>
        </form>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={alertMessage}
          buttons={['OK']}
        />

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message="Please wait..."
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: { setIsLoggedIn, setEmail, setMenuEnabled },
  component: Login,
});
