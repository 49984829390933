/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { Plugins, PushNotification, PushNotificationActionPerformed } from '@capacitor/core';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import * as firebase from 'firebase/app';
import Menu from './components/Menu';

import 'antd/dist/antd.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import {
  setIsLoggedIn, setEmail, loadUserData, setFirebaseToken, setHasRestoredPurchases, setFirebaseUid,
} from './data/user/user.actions';
import Account from './pages/Account';
import Login from './pages/Login';
import Signup from './pages/Signup';

import UnityPage from './pages/UnityPage';

import About from './pages/About';
import Tutorial from './pages/Tutorial';

import HomeOrTutorial from './components/HomeOrTutorial';


import 'firebase/auth';

let uri = 'https://wcdn-prisma-server.herokuapp.com';
if (process.env.NODE_ENV === 'development') {
  uri = 'http://192.168.1.3:4000';
}

let client = new ApolloClient({
  uri,
  credentials: 'include',
});

const App: React.FC = () => (
  <AppContextProvider>
    <IonicAppConnected />
  </AppContextProvider>
);

interface StateProps {
  darkMode: boolean;
  firebaseToken?: string;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setEmail: typeof setEmail;
  setFirebaseToken: typeof setFirebaseToken;
  setFirebaseUid: typeof setFirebaseUid;
  setHasRestoredPurchases: typeof setHasRestoredPurchases;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const { Device, SplashScreen, PushNotifications } = Plugins;

const IonicApp: React.FC<IonicAppProps> = ({
  // eslint-disable-next-line no-shadow
  darkMode, setIsLoggedIn, setEmail, loadUserData,
  // eslint-disable-next-line no-shadow
  firebaseToken, setFirebaseToken, setFirebaseUid, setHasRestoredPurchases,
}) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    loadUserData();

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        setFirebaseToken(idToken);
        setFirebaseUid(user.uid);
      }
    });

    SplashScreen.hide();

    async function setupPushNotifications() {
      const info = await Device.getInfo();
      const isWeb = (info.platform === 'web');
      if (isWeb) { return; }

      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          PushNotifications.register();
        }
      });

      // PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      //   console.debug(`Push registration success, token: ${token.value}`);
      // });

      // PushNotifications.addListener('registrationError', (error: any) => {
      //   console.error(`Error on registration: ${JSON.stringify(error)}`);
      // });

      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
        // eslint-disable-next-line no-alert
        alert(`Push received: ${JSON.stringify(notification)}`);
      });

      PushNotifications.addListener('pushNotificationActionPerformed', (notification: PushNotificationActionPerformed) => {
        // eslint-disable-next-line no-alert
        alert(`Push action performed: ${JSON.stringify(notification)}`);
      });
    }

    setupPushNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firebaseToken && token !== firebaseToken) {
      client = new ApolloClient({
        uri,
        credentials: 'include',
        request: async (operation) => {
          operation.setContext({
            headers: {
              Authorization: firebaseToken ? `Bearer ${firebaseToken}` : '',
            },
          });
        },
      });
      setToken(firebaseToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseToken]);


  return (
    <ApolloProvider client={client}>
      <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/tabs" component={MainTabs} />
              <Route path="/account" component={Account} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />

              <Route path="/unity" component={UnityPage} />

              <Route path="/tutorial" component={Tutorial} />
              <Route path="/about" component={About} />

              <Route
                path="/logout"
                render={() => {
                  firebase.auth().signOut().then(() => {
                    setFirebaseToken(undefined);
                    setFirebaseUid(undefined);
                    setIsLoggedIn(false);
                    setEmail(undefined);
                    setHasRestoredPurchases(false);
                  }).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(error.message);
                  });
                  return <Redirect to="/login" />;
                }}
              />
              <Route path="/" component={HomeOrTutorial} exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    firebaseToken: state.user.firebaseToken,
  }),
  mapDispatchToProps: {
    loadUserData,
    setIsLoggedIn,
    setEmail,
    setFirebaseToken,
    setFirebaseUid,
    setHasRestoredPurchases,
  },
  component: IonicApp,
});
