/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonContent,
  IonPage,
  IonButton,
  IonSlides,
  IonSlide,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  useIonViewWillEnter,
} from '@ionic/react';
import { arrowForward } from 'ionicons/icons';

import { connect } from '../data/connect';
import { setHasSeenTutorial, setMenuEnabled } from '../data/user/user.actions';

import './Tutorial.scss';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  firebaseToken?: string;
}

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
  setMenuEnabled: typeof setMenuEnabled;
}

interface TutorialProps extends OwnProps, StateProps, DispatchProps { }

const Tutorial: React.FC<TutorialProps> = ({
  // eslint-disable-next-line no-shadow
  history, setHasSeenTutorial, setMenuEnabled, firebaseToken,
}) => {
  const [showSkip, setShowSkip] = useState(true);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  useIonViewWillEnter(() => {
    setMenuEnabled(false);
  });

  const startApp = async () => {
    setHasSeenTutorial(true);

    if (firebaseToken) {
      setMenuEnabled(true);
      history.push('/tabs/library', { direction: 'none' });
    } else {
      history.push('/login', { direction: 'none' });
    }
    if (slidesRef.current) {
      slidesRef.current.slideTo(0);
    }
  };

  const handleSlideChangeStart = () => {
    slidesRef.current!.isEnd().then((isEnd) => setShowSkip(!isEnd));
  };

  return (
    <IonPage id="tutorial-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && <IonButton color="primary" onClick={startApp}>Skip</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding" scrollY={false}>
        <IonSlides ref={slidesRef} onIonSlideWillChange={handleSlideChangeStart} pager>
          <IonSlide>
            <img src="assets/img/appicon.png" alt="SkillsASAP Logo" className="slide-image" />
            <h2 className="slide-title">
              Welcome to
              {' '}
              <b>SkillsASAP</b>
            </h2>
            <p>
              Key takeaways from the world
              {'\''}
              s best technologies in 15 minutes
            </p>
          </IonSlide>

          <IonSlide>
            <img src="assets/img/undraw_selecting_1lx3.svg" alt="Personalized Recommendations" className="slide-image" />
            <h2 className="slide-title">Curated Technologies</h2>
            <p>
              Benefit from personalized recommendations and technology list curated by experts
            </p>
          </IonSlide>

          <IonSlide>
            <img src="assets/img/undraw_audio_player_yty9.svg" alt="Audio Player" className="slide-image" />
            <h2 className="slide-title">Listen</h2>
            <p>
              Listen to takeaways with the audio player
            </p>
          </IonSlide>

          <IonSlide>
            <img src="assets/img/undraw_abstract_x68e.svg" alt="Ready to Learn?" className="slide-image" />
            <h2 className="slide-title">Ready to Learn?</h2>
            <IonButton fill="clear" onClick={startApp}>
              Continue
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    firebaseToken: state.user.firebaseToken,
  }),
  mapDispatchToProps: ({
    setHasSeenTutorial,
    setMenuEnabled,
  }),
  component: Tutorial,
});
