/* eslint-disable react/prop-types */
import React from 'react';

import { IonLabel, IonChip } from '@ionic/react';
import { getRandomColor } from '../util/uiUtils';

interface PyramidItemProps {
  pyramid: any,
  history: any
}

const PyramidItem: React.FC<PyramidItemProps> = ({ pyramid, history }) => (
  <IonChip color={getRandomColor()} onClick={() => { history.push(`/tabs/pyramid/${pyramid.title}`); }}>
    <IonLabel>{pyramid.title}</IonLabel>
  </IonChip>
);

export default PyramidItem;
