/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonFooter,
  IonRow,
  IonCol,
  IonRange,
  IonLabel,
  IonAlert,
  IonToast,
  IonTitle,
  IonProgressBar,
  IonModal,
  IonList,
  IonItem,
  IonNote,
  useIonViewDidLeave,
  IonSlides,
  IonSlide,
} from '@ionic/react';
import {
  playCircleOutline,
  playBackCircleOutline,
  playForwardCircleOutline,
  pauseCircleOutline,
  listOutline,
  headsetOutline,
} from 'ionicons/icons';

import { Howl } from 'howler';

import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';

import './BlinkPage.scss';

// import { importMDX } from 'mdx.macro'
import marked from 'marked';

import { connect } from '../data/connect';
import { setListenMode } from '../data/user/user.actions';

const GET_BLINKS = gql`
  query blinks($title: String!) {
    blinks(where: { tech: { title: $title } }, orderBy: index_ASC) {
      title
      mdUrl
      audioUrl
    }
  }
`;

let sound: any;

function formatTime(secs: number): string {
  const minutes = Math.floor(secs / 60) || 0;
  const seconds = secs - minutes * 60 || 0;

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const BLOCK_DISPLAY = 'block';
const INLINE_BLOCK_DISPLAY = 'inline-block';
const NONE_DISPLAY = 'none';

type OwnProps = RouteComponentProps;

interface StateProps {
  listenMode: boolean;
}

interface DispatchProps {
  setListenMode: typeof setListenMode;
}

interface BlinkPageProps extends OwnProps, StateProps, DispatchProps { }

const BlinkPage: React.FC<BlinkPageProps> = ({
  match,
  listenMode,
  // eslint-disable-next-line no-shadow
  setListenMode,
}) => {
  const [
    getBlinks,
    { loading, error, data: lazyData },
  ] = useLazyQuery(GET_BLINKS, { fetchPolicy: 'no-cache' });

  const [finalData, setFinalData] = useState<any>();

  const [techTitle, setTechTitle] = useState('');
  const [blinkIndex, setBlinkIndex] = useState(0);

  const [slidesHtml, setSlidesHtml] = useState<any>([]);
  const [playlist, setPlaylist] = useState<any>([]);
  const [blinksOutlineHtml, setBlinksOutlineHtml] = useState<any>([]);

  const [blinkTitle, setBlinkTitle] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);

  const [playButtonDisplay, setPlayButtonDisplay] = useState(
    INLINE_BLOCK_DISPLAY,
  );
  const [pauseButtonDisplay, setPauseButtonDisplay] = useState(NONE_DISPLAY);

  const [timerString, setTimerString] = useState('0:00');
  const [durationString, setDurationString] = useState('0:00');
  const [progress, setProgress] = useState(0);

  const [alert, setAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [showLoadingToast, setShowLoadingToast] = useState(false);

  const [showBlinkListModal, setShowBlinkListModel] = useState(false);

  const pageRef = useRef<any>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const rangeRef = useRef<HTMLIonRangeElement>(null);

  function stop(): void {
    if (sound) {
      sound.stop();
      setPlayButtonDisplay(INLINE_BLOCK_DISPLAY);
      setPauseButtonDisplay(NONE_DISPLAY);
    }
  }

  function skip(direction: string): void {
    stop();

    let playlistIndex;
    if (direction === 'prev') {
      playlistIndex = blinkIndex - 1;
    } else {
      playlistIndex = blinkIndex + 1;
    }

    if (playlistIndex > 0 && playlistIndex < playlist.length) {
      setBlinkIndex(playlistIndex);
    }
  }

  function play(playlistIndex?: number): void {
    if (!playlist.length) {
      return;
    }

    if (!playlistIndex) {
      // eslint-disable-next-line no-param-reassign
      playlistIndex = blinkIndex;
    }
    const data = playlist[playlistIndex];

    if (data.howl) {
      sound = data.howl;
    } else {
      sound = new Howl({
        src: [data.url],
        html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
        onplay(): void {
          setDurationString(formatTime(Math.round(sound.duration())));

          setPlayButtonDisplay(NONE_DISPLAY);
          setPauseButtonDisplay(INLINE_BLOCK_DISPLAY);
        },
        onpause(): void {
          setPlayButtonDisplay(INLINE_BLOCK_DISPLAY);
          setPauseButtonDisplay(NONE_DISPLAY);
        },
        onstop(): void {
          setPlayButtonDisplay(INLINE_BLOCK_DISPLAY);
          setPauseButtonDisplay(NONE_DISPLAY);
        },
        onend(): void {
          skip('next');
        },
      });
      data.howl = sound;
    }

    if (!sound.playing()) {
      sound.play();
    }
    setPlayButtonDisplay(NONE_DISPLAY);
    setPauseButtonDisplay(INLINE_BLOCK_DISPLAY);
  }

  function pause(): void {
    if (sound && sound.playing()) {
      sound.pause();
    }
    setPlayButtonDisplay(INLINE_BLOCK_DISPLAY);
    setPauseButtonDisplay(NONE_DISPLAY);
  }

  function stopAll(): void {
    playlist.forEach((data: any) => {
      if (data.howl) {
        data.howl.stop();
      }
    });
    setPlayButtonDisplay(INLINE_BLOCK_DISPLAY);
    setPauseButtonDisplay(NONE_DISPLAY);
  }

  function seek(): void {
    if (rangeRef.current) {
      const percentage = (rangeRef.current.value as number) / 100;
      if (sound) {
        if (!sound.playing()) {
          sound.play();
        }
        sound.seek(sound.duration() * percentage);
      }
    }
  }

  function step(): void {
    if (sound && sound.playing()) {
      const currentSeek = sound.seek() || 0;
      setTimerString(formatTime(Math.round(currentSeek)));
      setProgress((currentSeek / sound.duration()) * 100 || 0);
    }
  }

  useIonViewDidLeave(() => {
    stop();
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSlideChangeStart = async () => {
    if (slidesRef.current) {
      setBlinkIndex(await slidesRef.current.getActiveIndex());
    }
    if (contentRef.current) {
      contentRef.current.scrollToTop(500);
    }
  };

  useEffect(() => {
    setInterval(() => {
      step();
    }, 1000);
  }, []);

  useEffect(() => {
    if (listenMode) {
      play();
    } else {
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenMode]);

  useEffect(() => {
    if ('techTitle' in match.params) {
      // eslint-disable-next-line
      let title = match.params['techTitle']
      setTechTitle(title);

      getBlinks({ variables: { title } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getMarkdownText(mdUrl: string): Promise<string> {
    const response = await fetch(mdUrl);
    return response.text();
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function updateSlides() {
      const slideHtmls = [];
      const tmpPlaylist = [];
      const tmpBlinksOutlineHtml = [];
      const { blinks } = finalData;

      const mdPromises = [];
      for (let i = 0; i < blinks.length; i += 1) {
        const blink = blinks[i];

        mdPromises.push(getMarkdownText(blink.mdUrl));

        tmpPlaylist.push({ url: blink.audioUrl, howl: null });

        tmpBlinksOutlineHtml.push(
          <IonItem
            key={i}
            button
            onClick={(): void => {
              setShowBlinkListModel(false);
              setBlinkIndex(i);
            }}
          >
            <IonNote slot="start">{i + 1}</IonNote>
            <IonLabel>{blink.title}</IonLabel>
          </IonItem>,
        );
      }
      const markdownTexts = await Promise.all(mdPromises);
      for (let i = 0; i < markdownTexts.length; i += 1) {
        const markdownText = markdownTexts[i];
        slideHtmls.push(
          <IonSlide key={i}>
            <div>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: marked(markdownText),
                }}
              />
            </div>
          </IonSlide>,
        );
      }

      const tmpSlidesHtml = (
        <IonSlides
          ref={slidesRef}
          onIonSlideWillChange={handleSlideChangeStart}
          pager={false}
          options={{ autoHeight: true }}
        >
          {slideHtmls}
        </IonSlides>
      );

      setSlidesHtml(tmpSlidesHtml);
      setPlaylist(tmpPlaylist);
      setBlinksOutlineHtml(tmpBlinksOutlineHtml);

      setShowLoadingToast(false);
    }
    if (finalData) {
      updateSlides();
    }
  }, [finalData]);

  useEffect(() => {
    if (finalData) {
      if (slidesRef.current) {
        slidesRef.current.slideTo(blinkIndex, 500);
      }
      setBlinkTitle(finalData.blinks[blinkIndex].title);
      setProgressPercentage((blinkIndex + 1) / finalData.blinks.length);

      if (listenMode) {
        setProgress(0);
        stopAll();
        play(blinkIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blinkIndex, finalData]);

  useEffect(() => {
    if (loading) {
      setShowLoadingToast(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!showLoadingToast && listenMode) {
      play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoadingToast]);

  if (error) {
    if (alert !== error.message) {
      setAlert(error.message.replace('GraphQL error: ', ''));
      if (!showAlert) {
        setShowAlert(true);
      }
    }
  }

  if (lazyData && !finalData) {
    setFinalData(lazyData);
  }

  // let filePath: string = '../content/A_Frame_in_15_Minutes/README.mdx'
  // if (blinkId !== -1) {
  //   filePath = paths[blinkId]
  // }
  // const Content = lazy(() => importMDX(filePath))
  // const Content = lazy(() => importMDX('../content/A_Frame_in_15_Minutes/README.mdx'))

  return (
    <IonPage id="blink-page" ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{blinkTitle}</IonTitle>
          <IonProgressBar value={progressPercentage} />

          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/library" />
          </IonButtons>
          <IonButtons slot="end">
            {/* <IonButton onClick={(): void => history.push('/unity')}>
              <IonIcon slot="icon-only" icon={cubeOutline} />
            </IonButton> */}
            {/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */}
            <IonButton onClick={() => setListenMode(!listenMode)}>
              <IonIcon slot="icon-only" icon={headsetOutline} />
            </IonButton>
            <IonButton onClick={(): void => setShowBlinkListModel(true)}>
              <IonIcon slot="icon-only" icon={listOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef} className="ion-padding">
        {slidesHtml}
        {/* <Suspense fallback={<div>Loading...</div>}>
          <Content />
        </Suspense> */}

        <IonToast
          isOpen={showLoadingToast}
          onDidDismiss={(): void => setShowLoadingToast(false)}
          message="Loading Blinks..."
        />

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={(): void => setShowAlert(false)}
          message={alert}
          buttons={['OK']}
        />

        <IonModal
          isOpen={showBlinkListModal}
          swipeToClose
          presentingElement={pageRef.current}
          onDidDismiss={(): void => setShowBlinkListModel(false)}
        >
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>{techTitle}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={(): void => setShowBlinkListModel(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonList>{blinksOutlineHtml}</IonList>
          </IonContent>
        </IonModal>
      </IonContent>

      <IonFooter style={{ display: listenMode ? BLOCK_DISPLAY : NONE_DISPLAY }}>
        <IonRow>
          <IonRange
            ref={rangeRef}
            max={100}
            value={progress}
            onTouchStart={(): void => pause()}
            onTouchEnd={(): void => seek()}
            onMouseDown={(): void => pause()}
            onMouseUp={(): void => seek()}
          >
            <IonLabel slot="start">{timerString}</IonLabel>
            <IonLabel slot="end">{durationString}</IonLabel>
          </IonRange>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton
              fill="clear"
              size="large"
              onClick={(): void => {
                skip('prev');
              }}
              disabled={blinkIndex === 0}
            >
              <IonIcon slot="icon-only" icon={playBackCircleOutline} />
            </IonButton>

            <IonButton
              fill="clear"
              size="large"
              style={{ display: playButtonDisplay }}
              onClick={(): void => {
                play();
              }}
            >
              <IonIcon slot="icon-only" icon={playCircleOutline} />
            </IonButton>
            <IonButton
              fill="clear"
              size="large"
              style={{ display: pauseButtonDisplay }}
              onClick={(): void => {
                pause();
              }}
            >
              <IonIcon slot="icon-only" icon={pauseCircleOutline} />
            </IonButton>

            <IonButton
              fill="clear"
              size="large"
              onClick={(): void => {
                skip('next');
              }}
              disabled={progressPercentage === 1}
            >
              <IonIcon slot="icon-only" icon={playForwardCircleOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    listenMode: state.user.listenMode,
  }),
  mapDispatchToProps: {
    setListenMode,
  },
  component: BlinkPage,
});
