/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
  IonAlert,
  IonTitle,
  IonButton,
  useIonViewWillLeave,
} from '@ionic/react';

import { Steps } from 'antd';
import {
  PlayCircleTwoTone,
  CheckCircleTwoTone,
  LoadingOutlined,
  WifiOutlined,
  DisconnectOutlined,
  CloseCircleTwoTone,
} from '@ant-design/icons';


import { gql } from 'apollo-boost';

import { useLazyQuery } from '@apollo/react-hooks';
import { connect } from '../data/connect';

const mqtt = require('mqtt');

const { Step } = Steps;

const GET_TECH = gql`
  query tech($title: String!, $index: Int!) {
    tech(where: { title: $title }) {
      handsOnTopics(where: { index: $index }, orderBy: index_ASC) {
        title
        handsOnActions(orderBy: index_ASC) {
          index
          title
          actionJson
        }
      }
    }
  }
`;

type OwnProps = RouteComponentProps;

interface StateProps {
  firebaseUid?: string;
}

interface HandsOnPageProps extends OwnProps, StateProps { }

let mqttClient: any;
let shouldConnectMqtt = true;

const HandsOnPage: React.FC<HandsOnPageProps> = ({ match, firebaseUid }) => {
  const [getTechs, { error, data: lazyData }] = useLazyQuery(GET_TECH, { fetchPolicy: 'no-cache' });

  const [isMqttConnected, setIsMqttConnected] = useState(false);

  const [handsOnActionIndex, setHandsOnActionIndex] = useState(0);

  const [actionInProgress, setActionInProgress] = useState(false);
  const [actionFailed, setActionFailed] = useState(false);
  const [actionSucceeded, setActionSucceeded] = useState(false);

  const [techDetails, setTechDetails] = useState<any>();

  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (firebaseUid && shouldConnectMqtt && !isMqttConnected) {
      shouldConnectMqtt = false;
      const options = {
        clientId: `ionic-${firebaseUid}`,
        username: 'fR0zbTrAixggZHgM02GdtdVi6otzVZuYGloG4D6aYcgLHknxaRBtTkAPyTckIx8d',
        password: 'fR0zbTrAixggZHgM02GdtdVi6otzVZuYGloG4D6aYcgLHknxaRBtTkAPyTckIx8d',
      };
      mqttClient = mqtt.connect('wss://mqtt.flespi.io:443', options);

      mqttClient.on('connect', () => {
        mqttClient.subscribe(firebaseUid, { qos: 2 }, (subscribeError: any) => {
          if (!subscribeError) {
            setIsMqttConnected(true);
          }
        });
      });

      mqttClient.on('close', () => {
        setIsMqttConnected(false);
      });

      mqttClient.on('message', (_topic: any, message: any) => {
        try {
          const statusJson = JSON.parse(message.toString());
          const { status, exitCode } = statusJson;
          if (status === 'FINISHED') {
            setActionInProgress(false);
            if (exitCode === 0) {
              setActionSucceeded(true);
            } else {
              setActionFailed(true);
              setAlertTitle('Action Failed');
              setAlertMessage('Please try again');
              setShowAlert(true);
            }
          }
        } catch (mqttParseError) {
          //
        }
      });

      const events = ['packetsend', 'packetreceive'];
      for (let i = 0; i < events.length; i += 1) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        mqttClient.on(events[i], (_packet: any) => {
          // log(packet);
          // if (packet.payload) {
          //   log(packet.payload.toString());
          // }
        });
      }
    }
  }, [firebaseUid, isMqttConnected]);

  useEffect(() => {
    if (!techDetails) return;
    const handsOnTopic = techDetails.handsOnTopics[0];
    const { handsOnActions } = handsOnTopic;
    if (handsOnActionIndex === handsOnActions.length) {
      setAlertTitle(handsOnTopic.title);
      setAlertMessage('Hands-On Completed');
      setShowAlert(true);
    }
  }, [techDetails, handsOnActionIndex]);

  useEffect(() => {
    if (actionSucceeded) {
      setHandsOnActionIndex((h) => h + 1);
      setActionSucceeded(false);
    }
  }, [actionSucceeded]);

  useIonViewWillLeave(() => {
    if (mqttClient) { mqttClient.end(); }
  });

  useEffect(() => {
    if ('techTitle' in match.params && 'index' in match.params) {
      // eslint-disable-next-line
      let title = match.params['techTitle']
      // eslint-disable-next-line
      let index = match.params['index']
      getTechs({ variables: { title, index } });
    }
  }, [getTechs, match.params]);

  if (error) {
    if (alertMessage !== error.message) {
      setAlertMessage(error.message.replace('GraphQL error: ', ''));
      setShowAlert(true);
    }
  }

  if (lazyData && techDetails !== lazyData.tech) {
    setTechDetails(lazyData.tech);
  }

  let handsOnActionListItems: any = (
    <div className="ion-padding">
      <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
      <span><IonSkeletonText animated style={{ width: '30%' }} /></span>
    </div>
  );
  if (techDetails) {
    handsOnActionListItems = [];
  }

  let handsOnTopic: any = {};
  let handsOnActions = [];
  const topicStepsHtml = [];
  if (techDetails) {
    // eslint-disable-next-line prefer-destructuring
    handsOnTopic = techDetails.handsOnTopics[0];
    handsOnActions = handsOnTopic.handsOnActions;

    for (let index = 0; index < handsOnActions.length; index += 1) {
      const handsOnAction = handsOnActions[index];

      let icon = <PlayCircleTwoTone />;
      if (index === handsOnActionIndex && actionInProgress) {
        icon = <LoadingOutlined />;
      }
      if (index === handsOnActionIndex && actionFailed) {
        icon = <CloseCircleTwoTone twoToneColor="#ff4961" />;
      }
      if (index < handsOnActionIndex) {
        icon = <CheckCircleTwoTone twoToneColor="#52c41a" />;
      }

      if (index === handsOnActionIndex) {
        topicStepsHtml.push(<Step
          key={index}
          icon={icon}
          title={handsOnAction.title}
          description={(
            // eslint-disable-next-line no-loop-func
            <IonButton
              disabled={actionInProgress || !isMqttConnected}
              color={actionInProgress ? 'warning' : 'primary'}
              // eslint-disable-next-line no-loop-func
              onClick={async () => {
                setActionFailed(false);
                setActionInProgress(true);
                if (mqttClient) {
                  mqttClient.publish(
                    firebaseUid, JSON.stringify(handsOnAction.actionJson),
                    { qos: 2 }, (publishError: any) => {
                      if (publishError) {
                        setAlertMessage(publishError.message);
                        setShowAlert(true);
                      }
                    },
                  );
                }
              }}
            >
              Start
            </IonButton>
            )}
        />);
      } else {
        topicStepsHtml.push(<Step key={index} icon={icon} title={handsOnAction.title} />);
      }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/library" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => {
              if (isMqttConnected) {
                setAlertMessage('Connected to SkillsASAP Server');
              } else {
                setAlertMessage('Not Connected to SkillsASAP Server');
              }
              setShowAlert(true);
            }}
            >
              {isMqttConnected && <WifiOutlined />}
              {!isMqttConnected && <DisconnectOutlined />}
            </IonButton>
          </IonButtons>

          <IonTitle>{techDetails && handsOnTopic.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {handsOnActionListItems}
        {techDetails && <Steps direction="vertical" size="small" current={handsOnActionIndex}>{topicStepsHtml}</Steps>}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertTitle}
          message={alertMessage}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    firebaseUid: state.user.firebaseUid,
  }),
  component: HandsOnPage,
});
